// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.css */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000; /* Fondo negro */
  overflow-x: hidden; /* Evitar desplazamiento horizontal */
}

#root {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,sBAAsB,EAAE,gBAAgB;EACxC,kBAAkB,EAAE,qCAAqC;AAC3D;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* index.css */\nhtml, body {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  background-color: #000; /* Fondo negro */\n  overflow-x: hidden; /* Evitar desplazamiento horizontal */\n}\n\n#root {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
