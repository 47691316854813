import React from 'react';
import './TeletextoHeader.css'; // Importamos los estilos
import logo from './radio_eko_logo2.png'; // Importamos la imagen correctamente

const Header = () => (
  <header>
    <div className="logo-container">
      <img src={logo} alt="Radio Eko Logo" className="logo" />
    </div>
    {/* 
      <p className="subtitle">tu texto aqui</p>
      Descomentar y modificar la línea anterior si se quiere añadir información o algún mensaje adicional
    */}
  </header>
);

export default Header;
