import React from 'react';
import './TeletextoHeader.css'; // Usaremos este archivo para los estilos
import './teletext.css'; // Añadir esta línea para incluir los estilos de secciones

const Section = ({ colorClass, title, src, height, page }) => {
  return (
    <section className={`${colorClass} widget-container`} style={{ height }}>
      <h2 className="section-title">
        <span className="section-title-text">{title}</span>
        <span className="ellipsis"></span>
        <span className={`page-number ${colorClass}`}>{page}</span>
      </h2>
      <iframe
        src={src}
        frameBorder="0"
        allowTransparency="true"
        title={title}
        style={{ height: '100%', width: '100%' }} 
      ></iframe>
    </section>
  );
};

export default Section;
