import React from 'react';
import './App.css';
import './teletext.css'; // Import teletext styles

import TeletextoHeader from './TeletextoHeader';
import Header from './Header';
import Section from './Section';

const App = () => {
  return (
    <div className="App">
      <TeletextoHeader />
      <Header />
      <Section colorClass="section-en-directo" title="EN DIRECTO" src="https://admin.radio.eslaeko.net/public/radio_esla_eko/embed" height="200px" page="102" />
      <Section colorClass="section-anterior" title="ANTERIOR" src="https://admin.radio.eslaeko.net/public/radio_esla_eko/history" height="200px" page="103" />
      <Section colorClass="section-parrillas" title="PARRILLAS" src="https://admin.radio.eslaeko.net/public/radio_esla_eko/schedule/embed?locale=es" height="400px" page="104" />
      <Section colorClass="section-podcasts" title="PODCASTS" src="https://admin.radio.eslaeko.net/public/radio_esla_eko/podcasts?embed=true" height="400px" page="105" />
      <Section colorClass="section-a-la-carta" title="A LA CARTA" src="https://admin.radio.eslaeko.net/public/radio_esla_eko/ondemand/embed" height="400px" page="106" />
    </div>
  );
};

export default App;
